import logo from "./ioss-logo.png";
import "./App.css";
import styled from "styled-components";

function App() {
  return (
    <div className="App">
      <Container>
        <Logo src={logo} alt="logo" />
        <h2>
          <MailLink href="mailto:info@iosoftwaresolutions.com">
            info@iosoftwaresolutions.com
          </MailLink>
        </h2>
      </Container>
    </div>
  );
}

const MailLink = styled.a`
  color: black;
`;

const Logo = styled.img`
  width: 500px;
`;

const Container = styled.div`
  justify-content: space-evenly;
  align-items: center;
  left: 50%;
  margin-top: 50px;
`;

export default App;
